html {
	overflow: hidden;
	height: 100%;
}

body {
	height: 100%;
	overflow: auto;
}

*:focus {
	outline: none !important;
}

.App {
	text-align: center;
}

::-webkit-scrollbar {
	width: 0px;
	height: 0px;
}

.str-chat {
	height: 100% !important;
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	background-color: transparent !important;
}

.str-chat__container {
	height: 100% !important;
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	background-color: transparent !important;
}

.str-chat__list {
	background-color: transparent !important;
}

.str-chat__reverse_infinite_scroll {
	background-color: transparent !important;
}

.str-chat.messaging {
	background-color: transparent !important;
}

.rounded-rev-left-green {
	background-size: 100% 100%;
	background-repeat: no-repeat;
	background-image: radial-gradient(
		circle at 0% 50%,
		rgba(255, 255, 255, 0) 20px,
		rgb(17, 186, 118) 21px
	);
}

.rounded-rev-left-blue {
	background-size: 100% 100%;
	background-repeat: no-repeat;
	background-image: radial-gradient(
		circle at 0% 50%,
		rgba(255, 255, 255, 0) 20px,
		rgb(23, 106, 201) 21px
	);
}

.rounded-rev-left-gray {
	background-size: 100% 100%;
	background-repeat: no-repeat;
	background-image: radial-gradient(
		circle at 0% 50%,
		rgba(255, 255, 255, 0) 20px,
		rgb(226, 226, 226) 21px
	);
}

.rounded-rev-left-lightgreen {
	background-size: 100% 100%;
	background-repeat: no-repeat;
	background-image: radial-gradient(
		circle at 0% 50%,
		rgba(255, 255, 255, 0) 20px,
		rgb(33, 223, 135) 21px
	);
}

.rounded-rev-left-lightblue {
	background-size: 100% 100%;
	background-repeat: no-repeat;
	background-image: radial-gradient(
		circle at 0% 50%,
		rgba(255, 255, 255, 0) 20px,
		rgb(94, 166, 255) 21px
	);
}

/* Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
	display: none;
}

.no-scrollbar {
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
}

.text-small-as-fuck {
	font-size: 8px;
}

.cloud-shadow {
	box-shadow: 0px 1px 26px rgba(68, 68, 68, 0.29);
}
.poll-name {
	overflow: hidden;
	text-overflow: ellipsis;
	word-wrap: break-word;
	display: block;
	line-height: 1em; /* a */
	max-height: 2em; /* a x number of line to show (ex : 2 line)  */
}

.bg-sidebar {
	background-color: #fbfcff;
}

.body-md-semibold {
	/* Body Semibold/Regular++ */

	font-family: Inter;
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 17px;

	color: #27282b;
}

.body-regular-small {
	font-family: Inter;
	font-style: normal;
	font-weight: 400;
	font-size: 13px;
	line-height: 16px;
	/* identical to box height */

	/* UI Text/Primary */

	color: #27282b;
}

.body-md-semibold-gray {
	/* Body Semibold/Regular++ */

	font-family: Inter;
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 17px;

	color: #84888d;
}

.body-md-black {
	/* Body Medium/Regular+ */
	font-family: Inter;
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 21px;
	/* identical to box height */
	color: #27282b;
}

.body-md {
	/* Body Medium/Regular+ */
	font-family: Inter;
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 21px;
	/* identical to box height */

	/* UI Text/Tertiary */
	color: #84888d;
}

.h1 {
	font-family: Inter;
	font-style: normal;
	font-weight: 600;
	font-size: 44px;
	line-height: 53px;
}

.h2 {
	/* Semibold Headings/Heading 2 */
	font-family: Inter;
	font-style: normal;
	font-weight: 600;
	font-size: 32px;
	line-height: 39px;
}
.h3 {
	/* Semibold Headings/Headings 3 */
	font-family: Inter;
	font-style: normal;
	font-weight: 600;
	font-size: 24px;
	line-height: 29px;
}

.h4 {
	/* Semibold Headings/Heading 4 */
	font-family: Inter;
	font-style: normal;
	font-weight: 600;
	font-size: 20px;
	line-height: 24px;
}

.h5 {
	/* Semibold Headings/Heading 4 */
	font-family: Inter;
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 20px;
}

.h1-plus {
	/* Bold Headings/Heading 1+ */
	font-family: Inter;
	font-style: normal;
	font-weight: bold;
	font-size: 44px;
	line-height: 53px;
}

.h2-plus {
	/* Bold Headings/Heading 2+ */
	font-family: Inter;
	font-style: normal;
	font-weight: bold;
	font-size: 32px;
	line-height: 39px;
}

.h3-plus {
	/* Bold Headings/Heading 3+ */
	font-family: Inter;
	font-style: normal;
	font-weight: bold;
	font-size: 24px;
	line-height: 29px;
	/* identical to box height */
}

.h4-plus {
	/* Bold Headings/Heading 4+ */
	font-family: Inter;
	font-style: normal;
	font-weight: bold;
	font-size: 20px;
	line-height: 24px;
}

.body-small-black {
	/* Body Medium/Small+ */

	font-family: Inter;
	font-style: normal;
	font-weight: 500;
	font-size: 13px;
	line-height: 16px;
	/* identical to box height */

	/* UI Text/Primary */

	color: #27282b;
}

.body-small-gray {
	/* Body Medium/Small+ */

	font-family: Inter;
	font-style: normal;
	font-weight: 500;
	font-size: 13px;
	line-height: 16px;
	/* identical to box height */

	/* UI Text/Tertiary */

	color: #84888d;
}

.body-small-semibold-black {
	/* Body Semibold/Small++ */

	font-family: Inter;
	font-style: normal;
	font-weight: 600;
	font-size: 13px;
	line-height: 16px;
	/* identical to box height */

	/* UI Text/Primary */

	color: #27282b;
}

.body-small-semibold-gray {
	/* Body Semibold/Small++ */

	font-family: Inter;
	font-style: normal;
	font-weight: 600;
	font-size: 13px;
	line-height: 16px;
	/* identical to box height */

	/* UI Text/Primary */

	color: #a4a6b4;
}
.body-xsmall-semibold-gray {
	/* Body Semibold/Small++ */

	font-family: Inter;
	font-style: normal;
	font-weight: 600;
	font-size: 11px;
	line-height: 13px;
	/* identical to box height */

	/* UI Text/Primary */

	color: #a4a6b4;
}
.body-xs-black {
	/* Body Medium/Small+ */

	font-family: Inter;
	font-style: normal;
	font-weight: 500;
	font-size: 11px;
	line-height: 13px;
	/* identical to box height */

	/* UI Text/Primary */

	color: #27282b;
}

.body-xxxs {
	/* Body Regular/XXXS */

	font-family: Inter;
	font-style: normal;
	font-weight: normal;
	font-size: 10px;
	line-height: 15px;
}

.body-xxs {
	/* Body Regular/XXS */

	font-family: Inter;
	font-style: normal;
	font-weight: normal;
	font-size: 11px;
	line-height: 13px;
}

.body-xs {
	/* Body Regular/XS */

	font-family: Inter;
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
}

.body-s {
	font-family: Inter;
	font-style: normal;
	font-weight: normal;
	font-size: 13px;
	line-height: 16px;
}

.body-regular {
	font-family: Inter;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 21px;
}

.body-large {
	/* Body Regular/Large */

	font-family: Inter;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 27px;
}

.body-xl {
	/* Body Regular/XL */

	font-family: Inter;
	font-style: normal;
	font-weight: normal;
	font-size: 18px;
	line-height: 22px;
}

.body-xxs-plus-plus {
	/* Body Regular/XXS */

	font-family: Inter;
	font-style: normal;
	font-weight: 600;
	font-size: 11px;
	line-height: 13px;
}

.body-xs-plus-plus {
	/* Body Regular/XS */

	font-family: Inter;
	font-style: normal;
	font-weight: 600;
	font-size: 12px;
}

.body-s-plus-plus {
	font-family: Inter;
	font-style: normal;
	font-weight: 600;
	font-size: 13px;
	line-height: 16px;
}

.body-regular-plus-plus {
	font-family: Inter;
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 21px;
}

.body-large-plus-plus {
	/* Body Regular/Large */

	font-family: Inter;
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 27px;
}

.body-xl-plus-plus {
	/* Body Regular/XL */

	font-family: Inter;
	font-style: normal;
	font-weight: normal;
	font-size: 18px;
	line-height: 22px;
}

.body-xxs-plus {
	/* Body Regular/XXS */

	font-family: Inter;
	font-style: normal;
	font-weight: 600;
	font-size: 11px;
	line-height: 13px;
}

.body-xs-plus {
	/* Body Regular/XS */

	font-family: Inter;
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
}

.body-s-plus {
	font-family: Inter;
	font-style: normal;
	font-weight: 500;
	font-size: 13px;
	line-height: 16px;
}

.body-regular-plus {
	font-family: Inter;
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 21px;
}

.body-regular-large {
	font-family: Inter;
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 27px;

	/* UI Text/Secondary */

	color: #a4a6b4;
}

.body-large-plus {
	/* Body Regular/Large */

	font-family: Inter;
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 27px;
}

.body-xl-plus {
	/* Body Regular/XL */

	font-family: Inter;
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 22px;
}
