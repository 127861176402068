.react-datepicker {
	font-family: inherit;
	font-size: 0.8rem;
	background-color: rgb(255, 255, 255);
	color: rgb(40, 40, 43);
	border: 0px;
	border-radius: 5px;
	display: inline-block;
	position: relative;
	box-shadow: 0 0 6px rgb(231, 231, 231);
	padding: 10px;
	z-index: 9999;
}
.react-datepicker__header {
	text-align: center;
	background-color: rgb(255, 255, 255);
	border-bottom: 0px;
	border-top-left-radius: 0;
	border-top-right-radius: 0;
	padding-top: 2px;
	position: relative;
}
.react-datepicker__today-button {
	background: black;
	border-top: 0px;
	cursor: pointer;
	text-align: center;
	font-weight: bold;
	clear: left;
}
.react-datepicker__day--outside-month,
.react-datepicker__day--weekend {
	color: rgba(156, 163, 175);
}
.react-datepicker__day--outside-month-selected {
	color: rgb(75, 62, 237);
}
.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
	border-radius: 0.3rem;
	background-color: rgb(239, 239, 253);
	color: rgb(75, 62, 237);
	border-radius: 75%;
	font-weight: 550;
}
.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover,
.react-datepicker__year-text--selected:hover,
.react-datepicker__year-text--in-selecting-range:hover,
.react-datepicker__year-text--in-range:hover {
	background-color: rgb(239, 239, 253);
	border-radius: 50%;
}
.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__month-text--keyboard-selected:hover,
.react-datepicker__quarter-text--keyboard-selected:hover,
.react-datepicker__year-text--keyboard-selected:hover {
	background-color: rgb(239, 239, 253);
}
.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
	border-radius: 50%;
	background-color: #f0f0f0;
}

.react-datepicker__triangle {
	background-color: black;
}
.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle,
.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle {
	background-color: transparent;
}

.react-datepicker-popper[data-placement^="bottom"]
	.react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^="top"]
	.react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^="bottom"]
	.react-datepicker__triangle::after,
.react-datepicker-popper[data-placement^="top"]
	.react-datepicker__triangle::after {
	box-sizing: content-box;
	position: absolute;
	border: 8px solid transparent;
	height: 0;
	width: 1px;
	content: "";
	z-index: -1;
	border-width: 8px;
	left: -8px;
	background-color: transparent;
}

.react-datepicker-popper[data-placement^="bottom"]
	.react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^="top"]
	.react-datepicker__triangle::before {
	background-color: transparent;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
	top: 0;
	margin-top: -8px;
	background-color: transparent;
}

.react-datepicker-popper[data-placement^="bottom"]
	.react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^="bottom"]
	.react-datepicker__triangle::after {
	border-top: none;
	background-color: transparent;
}

.react-datepicker-popper[data-placement^="bottom"]
	.react-datepicker__triangle::after {
	top: 0;
	background-color: transparent;
}

.react-datepicker-popper[data-placement^="bottom"]
	.react-datepicker__triangle::before {
	top: -1px;
	/* border-bottom-color: black; */
	background-color: transparent;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle {
	bottom: 0;
	margin-bottom: -8px;
	background-color: transparent;
}

.react-datepicker-popper[data-placement^="top"]
	.react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^="top"]
	.react-datepicker__triangle::after {
	border-bottom: none;
	border-top-color: transparent;
	background-color: transparent;
}

.react-datepicker-popper[data-placement^="top"]
	.react-datepicker__triangle::after {
	bottom: 0;
	background-color: transparent;
}

.react-datepicker-popper[data-placement^="top"]
	.react-datepicker__triangle::before {
	bottom: -1px;
	border-top-color: transparent;
	background-color: transparent;
}
.react-datepicker__day--keyboard-selected {
	border-radius: 0;
	background-color: #fff;
	color: #000;
}
.react-datepicker__day--today {
	font-weight: normal;
}
.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
	margin-top: 0;
	color: #000;
	font-weight: 550;
	font-size: 0.944rem;
}

.react-datepicker__input-container {
	position: static !important;
}
